<template>
  <div>
    <div class="top">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }" @click.native="change">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path:'/news'}">新闻报道</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="second">
      <div class="left">
        <a href="/newtwo">
          <img src="@/assets/img/jinjiang.jpg" alt="">
          <p class="title1">中国青年创新创业大赛</p>
          <p class="bottom-title1">第六届“创青春”中国青年创新创业大赛（互联网组）全国赛圆满结束</p>
        </a>
      </div>
      <div class="right">
        <a href="/newthree">
          <img src="@/assets/img/chuangkef.jpg" alt="">
          <p class="title">创客北京2019</p>
          <p class="bottom-title">2019年“创客中国”中小企业创新创业大赛暨”创客北京2019“创新创业大赛</p>
        </a>
        <a href="/newfour">
          <img src="@/assets/img/u30.jpg" alt="">
          <p class="title">中关村U30</p>
          <p class="bottom-title">【U30进行时】2019创青春-创新创业大赛</p>
        </a>
        <a href="/newfive">
          <img src="@/assets/img/8.jpg" alt="">
          <p class="title">创富中国“智能+“专场活动</p>
          <p class="bottom-title">2019创富中国“智能+”专场活动在成都成功举办</p>
        </a>
        <a href="/newsix">
          <img src="@/assets/img/quanqiu.png" alt="">
          <p class="title">全球青年创新大会</p>
          <p class="bottom-title">2019（第三届）全球青年创新大会颁奖</p>
        </a>
      </div>
    </div>
    <div class="third">
      <div class="third-title">
        <span class="third-title-one">新闻报道</span>
        <!-- <span class="third-title-two">
          <el-input
            v-model="value1"
            placeholder="请输入内容"
          >
            <i slot="prefix" class="el-input__icon el-icon-search" @click="search" />
          </el-input>
        </span>
        <span class="third-title-three">
          <el-date-picker
            v-model="value2"
            type="date"
            placeholder="选择日期"
          />
        </span> -->
      </div>
    </div>
    <div class="fourth">
      <ul>
        <li>
          <a href="/newnine" class="span"><img src="@/assets/img/yidengjiang.jpg" alt=""></a>
          <div class="middle">
            <a href="/newnine" class="fourth-p"><strong>“创客北京2019”创新创业大赛海淀区级赛 颁奖典礼成功举办 </strong></a>
            <p class="fourth-p2">7月25日,“创客北京2019”创新创业大赛海淀区级赛颁奖典礼在海淀创业园举办。经过复赛的激烈角逐和精彩比拼，海淀区55个企业和项目获得区级赛奖项，晋级市级赛。</p>
            <p class="fourth-p3">
              <span class="fourth-p3-1">创客北京2019”创新创业大赛海淀区级赛</span>
              <span class="fourth-p3-2">一等奖
                <img src="@/assets/img/media.png" alt="" class="media">
              </span>
            </p>
          </div>
          <div class="right">
            <span class="time">2019/7/25</span>
            <span class="time-jpg"><img src="@/assets/img/time.jpg" alt=""></span>
          </div>
        </li>
        <li>
          <a href="/newseven" class="span"><img src="@/assets/img/7.jpg" alt=""></a>
          <div class="middle">
            <a href="/newseven" class="fourth-p"><strong>创新创业大赛|决赛圆满告捷，冠军花落谁家？</strong></a>
            <p class="fourth-p2">3月29日，粤港澳大湾区中山青年人才创新创业大赛总决赛，在中山市易创空间创业孵化基地落下帷幕，按企业组和个人组角逐产生冠军、亚军、季军、优胜</p>
            <p class="fourth-p3">
              <span class="fourth-p3-1">中山青年人才创新创业大赛决赛</span>
              <span class="fourth-p3-2">第三名
                <img src="@/assets/img/media.png" alt="" class="media">
              </span>

            </p>
          </div>
          <div class="right">
            <span class="time">2019/3/29</span>
            <span class="time-jpg"><img src="@/assets/img/time.jpg" alt=""></span>
          </div>
        </li>
        <li>
          <a href="/newone" class="span"><img src="@/assets/img/6.jpg" alt=""></a>
          <div class="middle">
            <a href="/newone" class="fourth-p"><strong>首届汕头创业之星大赛颁奖典礼暨创新创业国际高峰论坛圆满落幕</strong></a>
            <p class="fourth-p2">1月14日，首届汕头创业之星大赛颁奖典礼暨创新创业国际高峰论坛在汕头市帝豪酒店圆满落幕。汕头市人民政府副市长林wwwww...</p>
            <p class="fourth-p3">
              <span class="fourth-p3-1">汕头.创业之星比赛</span>
              <span class="fourth-p3-2">三等奖
                <img src="@/assets/img/media.png" alt="" class="media">
              </span>

            </p>
          </div>
          <div class="right">
            <span class="time">2019/1/15</span>
            <span class="time-jpg"><img src="@/assets/img/time.jpg" alt=""></span>
          </div>
        </li>

        <li>
          <a href="/neweight" class="span"><img src="@/assets/img/zhineng3.jpg" alt=""></a>
          <div class="middle">
            <a href="/neweight" class="fourth-p"><strong>中国（小谷围）人工智能创新创业大赛圆满落下帷幕</strong></a>
            <p class="fourth-p2">2019年1月10日，由中国信息通信研究院、广州市番禺区人民政府主办、中国人工智能产业发展联盟、广州大学城管理委员会承办的中国（小谷围）人工智能创新创业大赛在广州大学城华南理工大学音乐厅圆满落下帷幕。</p>
            <p class="fourth-p3">
              <span class="fourth-p3-1">人工智能创新创业大赛</span>
              <span class="fourth-p3-2">优胜奖
                <img src="@/assets/img/media.png" alt="" class="media">
              </span>

            </p>
          </div>
          <div class="right">
            <span class="time">2019/1/10</span>
            <span class="time-jpg"><img src="@/assets/img/time.jpg" alt=""></span>
          </div>
        </li>
      </ul>
    </div>
    <!-- {{ abc.name }}
    {{ abc.author }} -->
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      currentRoute: state => state.nav.currentRoute
    })
  },
  mounted() {
  },

  methods: {
    ...mapActions({
      changeNavActive: 'nav/changeNavActive'
    }),
    change() {
      this.changeNavActive('首页')
    },
    search() {
      console.log(1)
    }
  }
}
</script>

<style lang="scss" scoped>
p{
  margin: 0;
}
img{
  display: block;
}
.top{
  margin-top: 65px;
  // padding: 0 205px;
  padding-left: 5%;
  width: 100%;
  height: 70px;
	background-color: #f2f2f2;
  text-align: left
}
.el-breadcrumb{
  line-height: 70px;
}
.sign{
  color: #ff3333 ;
}
//second
.second{
  // margin-top: 28px;
  //  padding: 0 150px;
  width: 1400px;
  margin: auto;
  padding: 20px;
   .left{
     position: relative;
     width: 49%;
     height: 510px;
     float: left;
     margin-right: 25px;
     margin-bottom: 30px;
     img{
       width: 100%;
       height: 100%;
       display: block;
      border-radius: 10px;
     }
     .title1{
       height: 50px;
       padding: 0 10px;
       background-color: #ffffff;
       opacity: 0.5;
       border-radius: 0px 4px 0px 20px;
       color: #191717;
       line-height: 50px;
       letter-spacing: 2px;
       font-size: 20px;
       position: absolute;
       top: 0;
       right: 0;
     }
     .bottom-title1{
       width: 100%;
       height: 68px;
       background-color: #000000;
       border-radius: 0px 0px 10px 10px;
       opacity: 0.8;
       font-size: 24px;
       line-height: 68px;
       letter-spacing: 0px;
       color: #ffffff;
       position: absolute;
       bottom: 0;
     }
   }
   .right{
     width: 48%;
     height: 510px;
     display: flex;
     justify-content: space-between;
     flex-wrap: wrap;
     a{
       width: 48%;
       height: 240px;
       margin-bottom:30px;
       display: block;
       position: relative;
       img{
      width: 100%;
       height: 100%;
       display: block;
       border-radius: 10px;
       }
       .title{
         padding: 0 10px;
         height: 30px;
         background-color: #ffffff;
         opacity: 0.5;
         border-radius: 0px 4px 0px 15px;
         color: #191717;
         font-size: 14px;
         line-height: 30px;
         letter-spacing: 1px;
         position: absolute;
         top: 0;
         right: 0;
       }
       .bottom-title{
         width: 100%;
         height: 50px;
         background-color: #000000;
         border-radius: 0px 0px 10px 10px;
         opacity: 0.8;font-size: 24px;
         line-height: 50px;
         letter-spacing: 0px;
         color: #ffffff;
         position: absolute;
         bottom: 0;
         white-space: nowrap;
         text-overflow: ellipsis;
         overflow: hidden;
       }
     }
   }
}
//third
.third{
  // padding: 0 190px;
    width: 1400px;
    margin: auto;
    padding: 20px;
  .third-title{
    width: 100%;
    padding: 50px 0 78px 0;
    .third-title-one{
      font-size: 26px;
      color: #000000;
       float: left;
    }
    .third-title-two{
      float: right;
      input::-webkit-input-placeholder{
            color:black;
        }
    }
    .third-title-three{
      margin-right: 20px;
      float: right;
       input::-webkit-input-placeholder{
            color:black;
        }
    }
  }
}
.el-select .el-input .el-select__caret{
  color:black;
}
.fourth{
  // padding: 0 220px 0 150px;
    width: 1400px;
    margin: auto;
    padding: 20px;
  ul li{
    padding: 30px;
    overflow: hidden;
    border-bottom: #e5e5e5 1px solid;
    position: relative;
    height: 247px;
    .span {
      float: left;
      height: 100%;
      width: 19.5%;
      margin-right: 25px;
      img{
      width:100%;
      height: 100%;
      border-radius: 8px;
    }
    }
    .time-jpg{
      width: 20px;
      height: 20px;
      margin-top: 5px;
      float: right;
      margin-right: 14px;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
}
.middle{
  width: 54%;
  position: relative;
  float: left;
}
   .time{
      float: right !important;
      text-align: right;
      font-size: 14px;
      line-height: 30px;
      color: #999999;
    }
  .fourth-p{
    width: 100%;
    line-height: 48px;
    text-align: left;
    font-size: 30px;
    color: #333333;
    float: left;
    }
    .fourth-p:hover{
      text-decoration:none;
      color: #e45151;
      transition: all 0.3s;
    }
    .fourth-p2{
      float: left;
      width: 100%;
      position: absolute;
      top:110px;
      overflow: hidden;
      white-space:nowrap;
      text-overflow:ellipsis;
      font-size: 14px;
      line-height: 30px;
      color: #999999;
    }
    .fourth-p3{
      float: left;
        position: absolute;
        top:160px;
      span{
        background-color: #eff3f5;
        border-radius: 4px;
      }
      .fourth-p3-1{
        padding: 8px 9px;
        font-size: 14px;
        line-height: 30px;
        letter-spacing: 1px;
        color: #999999;
        margin-right: 28px;
      }
      .fourth-p3-2{
        position: relative;
          padding: 8px 9px;
        font-size: 14px;
        line-height: 30px;
        letter-spacing: 1px;
        color: #999999;
      }
      .media{
        position: absolute;
        top: 3px;
        left: -15px;
      }
    }
    .pagination{
      margin: 62px 0;
    }
</style>
